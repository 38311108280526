remove the gold underline under all the h2 and h3
/* General Styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #fdf7d9; /* Theme Background */
}

/* Content Wrapper */
.content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align elements */
    width: 100%;
    max-width: 1100px; /* Limit max width */
    margin: 0 auto; /* Center the wrapper */
}

/* Row Styling */
.row {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
}

/* Hotel Slideshow and Video (75% width) */
.hotel-slideshow-container {
    flex: 3;
    width: 75%;
    position: relative;
    background-color: #fdf7d9; 
    border-radius: 8px;
    overflow: hidden;
}

.slideshow-slide {
    display: none;
}

.slideshow-slide img {
    width: 100%;
    border-radius: 8px;
}

/* Next & Previous Buttons */
.slideshow-prev,
.slideshow-next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    padding: 16px;
    color: #fdf7d9; /* Light background color */
    background-color: #483c32; /* Dark theme */
    
    font-size: 20px;
    
    user-select: none;
    transform: translateY(-50%);
}

.slideshow-prev {
    left: 10px;
    
}

.slideshow-next {
    right: 10px;
    
}

/* Button Hover Effects */
.slideshow-prev:hover,
.slideshow-next:hover {
    background-color: #d5a021; /* Gold hover */
}

/* Number text (1/3 etc) */
.slide-number {
    color: #483c32; /* Dark theme */
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* Caption Container */
.caption-container {
    text-align: center;
    background-color: #483c32; /* Dark theme */
    padding: 2px 16px;
    color: #fdf7d9; /* Light text */
}
.caption {
    color: #fdf7d9;
}
/* Thumbnail Row */
.thumbnail-row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.thumbnail-column {
    width: 16.66%;
}

.thumbnail-image {
    width: 100%;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.thumbnail-image:hover {
    border-color: #d5a021; /* Gold border on hover */
}

/* Add to Cart Section (25% width) */
.add-to-cart-section {
    flex: 1;
    background-color: #fdf7d9; /* Light theme */
    padding: 20px;
    border-radius: 8px;
    border: 2px solid #d5a021; /* Gold border */
}

/* Navigation Menu */
.navigation-menu {
    margin-bottom: 20px;
}

.menu-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.menu-item {
    text-decoration: none;
    color: #fdf7d9; /* Dark text */
    padding: 10px;
    background-color: #483c32; /* Gold background */
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s;
}

.menu-item:hover {
    background-color: #483c32; /* Dark on hover */
    color: #d5a021; /* Light text */
}

/* Hotel Info Card */
.hotel-info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hotel-thumbnail {
    width: 100%;
    border-radius: 8px;
}

.hotel-name {
    font-size: 1.5em;
    color: #483c32; /* Dark text */
    margin: 10px 0;
}

.add-to-cart-btn {
    width: 100%;
    padding: 10px;
    background-color: #d5a021; /* Gold background */
    color: #483c32; /* Dark text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
    background-color: #483c32; /* Dark hover */
    color: #fdf7d9; /* Light text */
}

/* Hotel Details Section */
.hotel-details-section {
    width: 100%;
    background-color: #fdf7d9; /* Light theme */
    padding: 20px;
    border-radius: 8px;
}

/* Section Header */
.section-header {
    font-size: 1.8em;               /* Large font size */
    color: #483c32;                 /* Dark text color */
    margin-bottom: 12px;            /* Space below the header */
    text-align: left;             /* Center alignment */
    font-weight: bold;              /* Bold text */
    display: block;                 /* Make sure display is block */
    z-index: 50;                    /* Bring to the front if overlapping */
    position: relative; 
    padding: 10px;  /* Add some padding around the text */            /* Ensure it stays in normal flow */
}

/* Ensure h2 elements are styled explicitly */
h2 {
    color: #483c32;                 /* Ensure color is set */
    margin: 0;                      /* Reset margin */
    padding: 0;
    display: block !important;  
    position: relative;  /* Ensures they don’t overlap */
    z-index: 1;  /* Lower than navbar */
     /* Match page background to cover overlap */
    opacity: 1;
    text-decoration: none !important;  /* Remove any text underlining */
    border-bottom: none !important;   /* Remove any bottom borders */                     /* Reset padding */
}


h3 {
    color: #483c32;                 /* Ensure color is set */
    margin: 0;                      /* Reset margin */
    padding: 0;
    display: block !important;  
    position: relative;  /* Ensures they don’t overlap */
    z-index: 1;  /* Lower than navbar */
     /* Match page background to cover overlap */
    opacity: 1;
    text-decoration: none !important;  /* Remove any text underlining */
    border-bottom: none !important;   /* Remove any bottom borders */                     /* Reset padding */
}

.details-content {
    font-size: 1em;
    line-height: 1.6;
    color: #483c32; /* Dark text */
}

.details-list {
    margin: 10px 0;
    padding-left: 20px;
}

.details-table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
}

.details-table td {
    padding: 10px;
    border-bottom: 1px solid #d5a021; /* Gold line */
}

/* Specifications and Customer Feedback */
.specifications-section {
    flex: 3;
    width: 75%;
    background-color: #fdf7d9; /* Gold theme */
    padding: 20px;
    border-radius: 8px;
}

.specification-card {
    margin-bottom: 20px;
    color: #483c32; /* Light text */
}

.features-list, .spec-list {
    padding-left: 20px;
    margin: 10px 0;
    color: #483c32; /* Dark text */
}

.package-table {
    width: 100%;
    margin: 10px 0;
    border-collapse: collapse;
}

.package-table td {
    padding: 10px;
    border-bottom: 1px solid #d5a021; /* Dark line */
}

/* Customer Feedback Section */
.customer-feedback-section {
    flex: 1;
    background-color: #fdf7d9; /* Light theme */
    padding: 20px;
    border-radius: 8px;
}

.feedback-content {
    font-size: 1em;
    color: #483c32; /* Dark text */
}

.contact-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.chat-btn {
    padding: 10px;
    background-color: #d5a021; /* Gold background */
    color: #483c32; /* Dark text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-btn:hover {
    background-color: #483c32; /* Dark hover */
    color: #fdf7d9; /* Light text */
}

/* Rating Bars */
.rating-bar {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.rating-bar .bar {
    width: 70%;
    height: 10px;
    background-color: #d5a021; /* Gold */
    margin: 0 10px;
    position: relative;
    border-radius: 5px;
}

.rating-bar .bar span {
    display: block;
    height: 100%;
    background-color: #483c32; /* Dark */
    border-radius: 5px;
}

.view-reviews-btn {
    display: inline-block;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #483c32; /* Dark text */
    background-color: #d5a021; /* Gold background */
    border: 2px solid #d5a021; /* Gold border */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.2s;
    text-align: center;
    text-decoration: none;
    width: 100%;
    max-width: 200px;
}

/* Hover effect */
.view-reviews-btn:hover {
    background-color: #483c32; /* Dark theme hover */
    color: #fdf7d9; /* Light text */
}

/* Active/Click effect */
.view-reviews-btn:active {
    transform: scale(0.95);
}

/* Map Location Section */
#map-location-section {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    background-color: #fdf7d9; /* Light theme */
    border-radius: 8px;
}

#map {
    width: 100%;
    height: 400px;
    background-color: #d5a021; /* Gold */
    border-radius: 8px;
}
