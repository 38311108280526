/* Container for max width */
.container10 {
    max-width: 1100px;
    margin: 0 auto;
}

/* Trip Section */
.trip-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    text-align: center;
}

.trip-container {
    max-width: 1100px;
    width: 100%;
}

/* Main Heading */
.main-heading {
    font-size: 36px;
    font-weight: 700;
    color: #483c32; /* Chocolate */
    margin: 0 0 20px;
}

/* Description */
.description {
    font-size: 16px;
    font-weight: 400;
    color: #483c32; /* Chocolate */
    line-height: 1.6;
    margin: 0;
}

/* Search Bar Container */
.search-bar,
.itinerary-search-bar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1150px;
    background: #fdf7d9;
    border: 1px solid #d5a021;
    border-radius: 10px;
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    padding: 8px;
    margin: 20px auto;
}

/* Individual Option Container */
.search-option {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    position: relative;
}

/* Label for options */
.option-label {
    font-size: 14px;
    font-weight: 600;
    color: #483c32;
    margin-bottom: 4px;
    cursor: pointer;
}

/* Dropdown styling */
.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fdf7d9;
    border: 1px solid #d5a021;
    border-radius: 5px;
    z-index: 10;
    padding: 8px;
    margin-top: 5px;
}

.dropdown label {
    display: block;
    margin: 5px 0;
}

.dropdown button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #483c32;
    color: #fdf7d9;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.dropdown button:hover {
    background-color: #d5a021;
}

/* Guest and Room Controls */
.guest-control,
.room-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}

.guest-control input,
.room-control input {
    width: 50px;
    text-align: center;
}

.guest-control button,
.room-control button {
    padding: 5px 10px;
    background-color: #483c32;
    color: #fdf7d9;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.guest-control button:hover,
.room-control button:hover {
    background-color: #d5a021;
}

/* Selected Options */
.selected-options {
    margin-top: 10px;
    font-size: 14px;
    color: #483c32;
    cursor: pointer;
}

.selected-options strong {
    font-weight: 600;
}

.selected-options span {
    margin-left: 5px;
}

/* Divider */
.divider {
    height: 60px;
    width: 1px;
    background: #d5a021;
    margin: 0;
}

/* Search Button */
.search-option-last {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 23px;
}

.search-button {
    display: flex;
    align-items: center;
    background-color: #483c32;
    color: #fdf7d9;
    border: none;
    border-radius: 10px;
    padding: 17px 50px;
    cursor: pointer;
    font-size: 14px;
}

.search-button:hover {
    background-color: #d5a021; /* Darker chocolate on hover */
}