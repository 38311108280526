/* General Styling */
.terms-container, .privacy-container {
  max-width: 900px;
  margin: 50px auto;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  background: #fdf7d9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  opacity: 0; /* Initial opacity for animation */
}

/* Fade-in Animation */
.fade-in {
animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
from {
  opacity: 0;
  transform: translateY(20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

/* Headings */
h1 {
  color: #483c32;
  text-align: center;
}

h2 {
  color: #483c32;
  border-bottom: 2px solid #d5a021;
  padding-bottom: 5px;
  margin-top: 20px;
  opacity: 0; /* Initial opacity for animation */
}

h3 {
  color: #483c32;
  border-bottom: 1px solid #d5a021;
  opacity: 0; /* Initial opacity for animation */
}

/* Slide-in effect */
.slide-in {
animation: slideIn 0.6s ease-in-out forwards;
}

@keyframes slideIn {
from {
  opacity: 0;
  transform: translateX(-30px);
}
to {
  opacity: 1;
  transform: translateX(0);
}
}

/* Text & List */
p, li {
  color: #483c32;
  line-height: 1.6;
}

ul {
  padding-left: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .terms-container, .privacy-container {
    width: 90%;
    padding: 15px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }
}
