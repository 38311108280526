/* General Styling */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fdf7d9;
    color: #483c32;
}

/* Hero Section */
.itinerary-hero {
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 60px 20px;
    color: #fdf7d9;
}

.itinerary-hero h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #483c32;
}

.itinerary-hero p {
    font-size: 1em;
    margin-bottom: 10px;
    color: #483c32;
}

/* Navigation Bar */
.itinerary-navbar {
    background-color: #483c32;
    padding: 10px 0;
    text-align: center;
}

.itinerary-navbar ul {
    list-style-type: none;
    padding: 0;
}

.itinerary-navbar ul li {
    display: inline;
    margin: 0 20px;
}

.itinerary-navbar ul li a {
    color: #fdf7d9;
    text-decoration: none;
    font-weight: bold;
}

.itinerary-navbar ul li a:hover {
    color: #d5a021;
}

/* Section Styling */
.itinerary-section {
    padding: 10px 0px;
    text-align: center;
}

.itinerary-timeline {
    position: relative;
    width: 80%;
    margin: 50px auto;
    padding: 30px;
}

/* Line connecting the timeline circles */
.itinerary-timeline::before {
    content: "";
    position: absolute;
    left: 40px;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #d5a021;
}

/* Timeline Circles */
.itinerary-circle {
    width: 20px;
    height: 20px;
    background-color: #d5a021;
    border-radius: 50%;
    position: absolute;
    left: 32px;
}

.itinerary-circle::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #d5a021;
}

/* Timeline Content */
.itinerary-timeline-content {
    margin-left: 50px;
    background: #fdf7d9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Timeline Activity List */
.itinerary-timeline-content ul {
    list-style: none;
    padding: 0;
}

.itinerary-timeline-content ul li {
    background: #d5a021;
    color: #fdf7d9;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
}

/* Carousel */
.itinerary-carousel, .itinerary-carousel2 {
    display: flex;
    overflow-x: scroll;
    margin-top: 10px;
}

.itinerary-carousel img, .itinerary-carousel2 img { 
    width: 100px;
    height: calc(100% * 9 / 16);
    margin-right: 10px;
    border-radius: 8px;
}

/* Contact Section */
.itinerary-contact-section {
    background: #d5a021;
    padding: 20px;
    text-align: center;
    color: #483c32;
}

/* Footer */
.itinerary-footer {
    background: #483c32;
    color: #fdf7d9;
    text-align: center;
    padding: 10px;
}
